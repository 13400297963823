import React, { Dispatch, SetStateAction } from "react";
import { Button, WhatsAppButton } from "../../Button";
import { COLORS } from "../../../constants";

type FAQProps = {
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

export const FAQ: React.FC<FAQProps> = ({ setPageId }) => {
    return (
        <section>
            <section>
                <h1>Was ist Ludopia?</h1>
                <p>
                    Ludopia ist die Plattform für alle Spielbegeisterten! Sie
                    erleichtert es, gemeinsam zu spielen, Termine zu finden und
                    neue Freunde zu gewinnen. Zunächst beschränke ich mich auf
                    meinen Freundes- und Bekanntenkreis, aber eine Erweiterung
                    ist in Planung.
                </p>
            </section>

            <section>
                <h1>Klingt gut! Was muss ich jetzt tun?</h1>
                <p>
                    Um Teil der Gemeinschaft zu werden, tritt einfach unserer
                    WhatsApp Community bei. Dort findest du Gruppen zu
                    verschiedenen Themen, denen du beitreten kannst.
                </p>
                <WhatsAppButton
                    link="https://chat.whatsapp.com/Jzj0x3eahqRGHfACOIZaLq"
                    label="zur WhatsApp Community"
                />
                <Button
                    onClick={() => setPageId("gruppen")}
                    label="Übersicht Gruppen"
                    backgroundColor={COLORS.whatsapp}
                />
            </section>

            <section>
                <h1>Wann findet etwas statt?</h1>
                <p>
                    In den Gruppen kannst du Termine für Events finden. Alle
                    Veranstaltungen sind auch im gemeinsamen Kalender gesammelt,
                    den du in deiner Kalender-App abonnieren kannst:{" "}
                    <a
                        href="https://calendar.google.com/calendar/ical/ludopia%40bschmidhuber.at/public/basic.ics"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Kalender abonnieren
                    </a>
                </p>
                <Button
                    onClick={() => setPageId("kalender")}
                    label="zum Kalender"
                    backgroundColor="cornflowerblue"
                />
            </section>

            <section>
                <h1>Wie kann ich teilnehmen?</h1>
                <p>
                    In jedem Kalender-Eintrag findest du einen Link zur
                    Anmeldung. Dort siehst du auch, ob ein Event bereits
                    ausgebucht ist.
                </p>
            </section>
            <section>
                <h1>Kann ich Freunde zu Events mitbringen?</h1>
                <p>
                    Ja, du kannst gerne Freunde zu den Events mitbringen! Bitte
                    kläre dies jedoch vorher mit mir, damit wir die
                    Teilnehmerzahl im Blick behalten können.
                </p>
            </section>
            <section>
                <h1>Kostet Ludopia etwas?</h1>
                <p>
                    Nein, Ludopia ist ein Hobbyprojekt und kostenlos. Einige
                    Events können geringe Kosten verursachen (z.B. Eintritt in
                    ein Lokal), dies wird jedoch vorher angekündigt.
                </p>
            </section>
        </section>
    );
};
